import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Grid from "./Grid"
import Wordmark from "../../images/logos/wordmark.svg"
import Expand from "../../images/icons/PlusIcon.svg"
import RightArrowLarge from "../../images/icons/RightArrowLarge.svg"

const HeaderWrapper = styled.header`
  background: var(--white);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 997;

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;

    @media screen and (min-width: 800px) {
      padding: 1rem 0;
    }

    .logo-wrapper {
      width: 130px;
      .site-title {
        margin: 0;
      }
      .screen-reader-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
      }
    }

    @keyframes slideIn {
      from {
        transform: translateX(100vw);
      }
      to {
        transform: translateX(0vw);
      }
    }

    @keyframes slideOut {
      from {
        transform: translateX(0vw);
      }
      to {
        transform: translateX(100vw);
      }
    }

    @keyframes rotate45 {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(45deg);
      }
    }
    .nav-toggle {
      display: none;
    }

    .nav-toggle ~ .nav {
      /* transition: transform 0.5s; */
      /* animation-name: slideOut;
      animation-duration: 1s;
      animation-fill-mode: forwards; */
    }
    .nav-toggle:checked ~ .nav {
      animation-name: slideIn;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
      /* right: 0; */
    }

    .nav-toggle:checked ~ .hamburger {
      z-index: 1001;
      animation-name: rotate45;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
    }

    .nav-toggle:checked ~ .nav .nav-links {
      position: relative;
      z-index: 1000;
    }
    .nav-toggle:checked ~ .close {
      z-index: 1001;
    }
    .nav-toggle:checked ~ .backdrop {
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      z-index: 998;
      background: rgba(0, 0, 0, 0.85);
      cursor: default;
    }
    .hamburger {
      color: var(--navy);
      cursor: pointer;
      @media screen and (min-width: 800px) {
        display: none;
      }
      .nav-toggle-label {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .nav {
      height: 100vh;
      transform: translateX(100vw);
      position: absolute;
      right: 0;
      top: 0;
      z-index: 999;
      /* animation-name: slideOut;
      animation-duration: 0.5s;
      animation-fill-mode: forwards; */
      @media screen and (min-width: 800px) {
        all: unset;
      }

      .nav-logo {
        padding: 13px 0 0 1rem;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1001;

        @media screen and (min-width: 800px) {
          display: none;
        }
      }
      .close {
        color: var(--white);
        display: block;
        position: absolute;
        right: 1rem;
        top: 1rem;
        cursor: pointer;
        z-index: 1001;
        @media screen and (min-width: 800px) {
          display: none;
        }
      }
    }
    .nav-links {
      background: var(--light-blue);
      display: flex;
      flex-direction: column;
      align-items: left;
      list-style: none;
      margin: 0;
      min-height: 100%;
      padding: 5rem 0 0;
      width: 100vw;
      @media screen and (min-width: 800px) {
        background: unset;
        flex-direction: row;
        min-height: unset;
        padding: unset;
        width: unset;
      }
      .nav-link {
        font-size: 2rem;
        margin: 0 0 1rem 0rem;
        padding-left: 1rem;
        @media screen and (min-width: 800px) {
          font-size: 1.5rem;
          margin: 0 1rem 0 0;
          padding-left: unset;
        }
        a {
          align-items: center;
          color: var(--navy);
          display: flex;

          font-family: "Galano Grotesque Semi Bold", sans-serif;
          justify-content: space-between;
          padding-right: 1rem;
          text-transform: lowercase;

          .mobile-icon {
            max-width: 65%;
            height: auto;
            @media screen and (min-width: 800px) {
              display: none;
            }
          }
        }
      }
      .nav-link:last-child {
        margin: 0 0 0 0;
      }

      .nav-link:hover {
        opacity: 0.7;
      }

      .nav-mobile-only {
        background: var(--medium-gray);
        display: flex;
        align-items: center;
        padding-left: 1rem;
        position: absolute;
        bottom: 80px;
        left: 0;
        right: 0;
        height: 40vh;

        @media screen and (min-width: 800px) {
          display: none;
        }

        a {
          color: var(--navy);
          font-family: "DIN Next LT Pro Bold", sans-serif;
          font-size: 1.5rem;
          text-transform: lowercase;
        }
      }
    }
  }
`

const Header = ({ type, navtype }) => {
  return (
    <HeaderWrapper type={type} navtype={navtype}>
      <Grid>
        <div className="header-wrapper">
          <div className="logo-wrapper">
            <Link to="/">
              <Wordmark />
            </Link>
            <h1 className="site-title" itemProp="headline">
              <span className="screen-reader-only">The Build</span>
            </h1>
          </div>
          <input type="checkbox" id="nav-toggle" className="nav-toggle" />
          <div className="hamburger">
            <label htmlFor="nav-toggle" className="nav-toggle-label">
              <span>
                <Expand />
              </span>
            </label>
          </div>
          <nav className="nav">
            <div className="nav-logo">
              <Wordmark />
            </div>
            <ul className="nav-links">
              <li className="nav-link">
                <Link to="/about">
                  About <RightArrowLarge className="mobile-icon" />
                </Link>
              </li>
              <li className="nav-link">
                <Link to="/work">
                  Work <RightArrowLarge className="mobile-icon" />
                </Link>
              </li>
              <li className="nav-link">
                <Link to="/contact">
                  Contact <RightArrowLarge className="mobile-icon" />
                </Link>
              </li>
              <li className="nav-mobile-only">
                <a className="nav-contact" href="mailto:info@wearethebuild.com">
                  info@wearethebuild.com
                </a>
              </li>
            </ul>
          </nav>
          <label
            htmlFor="nav-toggle"
            className="backdrop"
            tabIndex="-1"
            aria-hidden="true"
            hidden
          ></label>
        </div>
      </Grid>
    </HeaderWrapper>
  )
}

export default Header
