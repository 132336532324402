import React from "react"
import styled from "styled-components"
import Header from "./Header"
import Footer from "./Footer"
import "../../styles/normalize.css"
import "../../styles/global.css"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* line-height: 1.5rem; */
`

const Content = styled.div`
  flex-grow: 1;
  /* The following CSS allows for the right hand off canvas menu to work in full screen */
  position: relative;
`

const Layout = props => {
  return (
    <Container>
      <Content>
        <Header />
        {props.children}
      </Content>
      <Footer />
    </Container>
  )
}

export default Layout
