import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Grid from "./Grid"
import RightArrowSmall from "../../images/icons/RightArrowSmall.svg"

const FooterWrapper = styled.footer`
  background: var(--medium-gray);

  .footer-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;

    @media screen and (min-width: 800px) {
      flex-direction: row;
      padding: 0 10rem;
    }

    .footer-nav,
    .footer-info {
      list-style: none;
      padding: 0;
      margin: 2rem 0;

      .footer-item {
        margin-bottom: 1em;
      }

      .footer-item:last-child {
        margin-bottom: 0;
      }

      .footer-link a {
        color: var(--navy);
        font-family: "Galano Grotesque Semi Bold";
      }

      .footer-email a {
        color: var(--navy);
      }
    }
  }
`

const Footer = () => {
  return (
    <FooterWrapper>
      <Grid>
        <div className="footer-wrapper">
          <ul className="footer-nav">
            <li className="footer-item footer-link">
              <Link to="/contact">
                Get in touch <RightArrowSmall />
              </Link>
            </li>
            <li className="footer-item footer-email">
              <a href="mailto:info@wearethebuild.com">info@wearethebuild.com</a>
            </li>
            <li className="footer-item footer-link">
              <Link to="/about">
                Learn more about us <RightArrowSmall />
              </Link>
            </li>
          </ul>
          <ul className="footer-info">
            <li className="footer-item">168 King Street East</li>
            <li className="footer-item">Toronto, Ontario M5A 4S4</li>
          </ul>
        </div>
      </Grid>
    </FooterWrapper>
  )
}

export default Footer
